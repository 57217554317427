import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import shapImg from "../../assets/shape.png";
import { AppContext } from "../../context/AppContext";
import toast from "react-hot-toast";

const YouTubeTut = () => {
  const [screenCard, setScreenWithCard] = useState(3);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: screenCard,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // Function to handle screen resizing
    const handleResize = () => {
      if (window.innerWidth > 992) {
        setScreenWithCard(3);
      }
      if (window.innerWidth < 992) {
        setScreenWithCard(2);
      }
      if (window.innerWidth < 600) {
        setScreenWithCard(1);
      }
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <section className="youtube-section p-100">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="you-head">
              <img src={shapImg} alt="" className="m-auto" />
              <h2>Loin's Den Tutorial Videos</h2>
            </div>
          </div>
        </div>
        <div className="ser-slider">
          <Slider {...settings}>
            <div className="col-lg-4">
              <div className="you-box">
                <iframe
                  width="100%"
                  height="330"
                  src="https://www.youtube.com/embed/5abqE8yvwcE"
                  title="Boxing First Punch JAB | Boxing Tutorial For Beginner | बॉक्सिंग टुटोरिअल । बॉक्सिंग फर्स्ट पंच"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                <h4>
                  <img src={shapImg} alt="shape" />
                  <span>DIET PLANS</span>
                </h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="you-box">
                <iframe
                  width="100%"
                  height="330"
                  src="https://www.youtube.com/embed/5abqE8yvwcE"
                  title="Boxing First Punch JAB | Boxing Tutorial For Beginner | बॉक्सिंग टुटोरिअल । बॉक्सिंग फर्स्ट पंच"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                <h4>
                  <img src={shapImg} alt="shape" />
                  <span>DIET PLANS</span>
                </h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="you-box">
                <iframe
                  width="100%"
                  height="330"
                  src="https://www.youtube.com/embed/5abqE8yvwcE"
                  title="Boxing First Punch JAB | Boxing Tutorial For Beginner | बॉक्सिंग टुटोरिअल । बॉक्सिंग फर्स्ट पंच"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                <h4>
                  <img src={shapImg} alt="shape" />
                  <span>DIET PLANS</span>
                </h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="you-box">
                <iframe
                  width="100%"
                  height="330"
                  src="https://www.youtube.com/embed/5abqE8yvwcE"
                  title="Boxing First Punch JAB | Boxing Tutorial For Beginner | बॉक्सिंग टुटोरिअल । बॉक्सिंग फर्स्ट पंच"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
                <h4>
                  <img src={shapImg} alt="shape" />
                  <span>DIET PLANS</span>
                </h4>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default YouTubeTut;
