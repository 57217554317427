import React from 'react'
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import teamImg from '../../assets/team1.jpg'
import { Link } from 'react-router-dom';

const TeamCard = ({data}) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
  return (
        <div className="team-box">
            <div className="team-img">
                <img src={`${baseUrl}${data.img}`} alt="harsh"/>
            </div>
            <div className="team-text">
                <div className="text">
                    <p>{data.profession}</p>
                    <h6>{data.name}</h6>
                </div>
                <ul>
                    <li><Link to={data.facebookUrl}><i><FaFacebook/></i></Link></li>
                    <li><Link to={data.instagramUrl}><i><FaInstagram/></i></Link></li>
                    <li><Link to={data.twitterUrl}><i><FaXTwitter/></i></Link></li>
                </ul>
            </div>
        </div>
  )
}

export default TeamCard