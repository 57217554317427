import React from 'react'
export const BranchTiming = () => {
  return (
    <section className="contact-section p-100">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-4 col-md-6 g-0">
                    <div className="contact-box time-box">
                        <h4><span>ADULT BATCHES Morning</span></h4>
                        <div className="text">
                            <div className="c-text">
                                <h6>06:00 AM - 07:00 AM</h6>
                                <h6>07:00 AM - 08:00 AM</h6>
                                <h6>08:00 AM - 09:00 AM</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 g-0">
                    <div className="contact-box time-box">
                        <h4><span>Kids Batches </span></h4>
                        <div className="text">
                            <div className="c-text">
                                <h6>04:00 PM - 05:00 PM</h6>
                                <h6>05:00 PM - 06:00 PM</h6>
                                <h6>06:30 PM - 07:30 PM</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 g-0">
                    <div className="contact-box time-box">
                        <h4><span>adult batches evening</span></h4>
                        <div className="text">
                            <div className="c-text">
                                <h6>06:00 PM - 07:00 PM</h6>
                                <h6>07:00 PM - 08:00 PM</h6>
                                <h6>08:00 PM - 09:00 PM</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
