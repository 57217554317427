import React from 'react'
import shapImg from '../../assets/shape.png'
import gloves1Img from '../../assets/gloves-1.webp'
import gloves3Img from '../../assets/gloves-3.webp'
import gloves4Img from '../../assets/gloves-4.webp'

const FeeStructure = ({data}) => {
    function imgHandler(index){
        if(index === 0){
            return gloves1Img;
        }
        if(index === 1){
            return gloves4Img;
        }
        if(index === 2){
            return gloves3Img;  // Assuming index 2 should return gloves4Img
        }
        if(index === 3){
            return gloves4Img;
        }
    }

    return (
        <section className="price-section p-100">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-7">
                        <div className="price-head">
                            <img src={shapImg} alt="shape" className='m-auto'/>
                            <h3>Martial Arts Training Fee Structure</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {
                        data && data.map((fee, index) => (
                            <div key={fee._id} className="col-lg-3 col-md-6 mt-4">
                                <div className="price-box">
                                    <h4 className='uppercase'>{fee.package}</h4>
                                    <div className="price-list">
                                        <img src={imgHandler(index)} alt="gloves"/> {/* Corrected usage */}
                                        <ul>
                                            <li><i className="fa-solid fa-rectangle-list"></i><span>Male <em>{`₹${fee?.male}`}</em></span></li>
                                            <li><i className="fa-solid fa-rectangle-list"></i><span>Female <em>{`₹${fee?.female}`}</em></span></li>
                                            <li><i className="fa-solid fa-rectangle-list"></i><span>Couple <em>{`₹${fee?.couple}`}</em></span></li>
                                            <li><i className="fa-solid fa-rectangle-list"></i><span>Kids <em>{`₹${fee?.kids}`}</em></span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default FeeStructure
