import React from 'react'
import shapeImg from '../../assets/shape.png'
import { Link } from 'react-router-dom'

const AboutBranch = ({name, email, description, mobile_no, locationUrl}) => {
  return (
    <section className="location-section p-50">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="ls-head">
                        <img src={shapeImg} alt="shape" className='m-auto'/>
                        <h2>{`Lion's Den MMA Academy ${name}`}</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="ls-content">
                        <h1>{`${name}'s Premier MMA Training Centers`}</h1>
                        <p>{description}</p>
                        <h5>Join Us Today</h5>
                        <p>{`Experience the best in MMA training at any of our ${name} locations. Whether you are
                            looking to improve your fitness, learn self-defense, or compete at a professional level,
                            Lion's Den MMA Academy has the right program for you. Visit your nearest branch and
                            start your journey with us today!`}</p>
                        <h6>Mobile No. : <Link to={`tel:+91${mobile_no}`}>{`+91 ${mobile_no}`}</Link></h6>
                        <h6>Email Id : <Link
                                to={`mailto:${email}`}>{email}</Link>
                        </h6>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="ls-map">
                        <iframe
                            src={locationUrl}
                            width="100%" height="100%" className='border-none' allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default AboutBranch