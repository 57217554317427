import React, { useEffect, useState } from 'react';
import ShapImg from '../../assets/shape.png';
import TimeTableCard from './TimeTableCard';

const Schedule = ({ name, data }) => {
  const [dayName, setDayName] = useState('monday');
  const [timeData, setTimeData] = useState([]);

  const findDayNameFromObject = (dName) => {
    const day = data.find((day) => day.day.toLowerCase() === dName.toLowerCase());
    if (day) {
      setTimeData(day.times); // Set timeData to the day's times
    } else {
      setTimeData([]); // Reset if no day is found
    }
  };

  useEffect(() => {
    findDayNameFromObject(dayName);
  }, [dayName, data]); // Listen to both dayName and data for changes

  return (
    <section className="schedule-section p-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="ss-head">
              <h5><img src={ShapImg} alt="" /><span>Time & Table</span></h5>
              <h2>Weekly Training Schedule</h2>
              <p>{`Lion’s Den Fight Club is one of its kind martial arts training centres in ${name || ''}`}</p>
            </div>
          </div>
        </div>
        <div className="ss-list">
          <ul>
            <li onClick={() => setDayName('monday')} className={`${dayName === 'monday' ? 'active' : ''}`}>Monday</li>
            <li onClick={() => setDayName('tuesday')} className={`${dayName === 'tuesday' ? 'active' : ''}`}>Tuesday</li>
            <li onClick={() => setDayName('wednesday')} className={`${dayName === 'wednesday' ? 'active' : ''}`}>Wednesday</li>
            <li onClick={() => setDayName('thursday')} className={`${dayName === 'thursday' ? 'active' : ''}`}>Thursday</li>
            <li onClick={() => setDayName('friday')} className={`${dayName === 'friday' ? 'active' : ''}`}>Friday</li>
            <li onClick={() => setDayName('saturday')} className={`${dayName === 'saturday' ? 'active' : ''}`}>Saturday</li>
            <li onClick={() => setDayName('sunday')} className={`${dayName === 'sunday' ? 'active' : ''}`}>Sunday</li>
          </ul>
        </div>
        <div className="ss-table">
          {
            timeData && timeData.length > 0 ? (
              timeData.map((time, index) => (
                <TimeTableCard 
                  key={index} 
                  description={time?.description} 
                  morningTime={time?.morningTime} 
                  eveningTime={time?.eveningTime} 
                />
              ))
            ) : (
              <p>No schedule available for {dayName.charAt(0).toUpperCase() + dayName.slice(1)}</p>
            )
          }
        </div>
      </div>
    </section>
  );
};

export default Schedule;
