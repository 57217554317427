import React, { useContext, useEffect, useState } from 'react'
import { AdminContext } from '../../../context/AdminContext';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CiPaperplane } from "react-icons/ci";

const Contact = () => {
  const { isLoading, contactData, getContactDetails } = useContext(AdminContext);
  const [currUserView, setIsCurrentUserView] = useState({ isActive: false, data: null });
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!contactData) {
      getContactDetails();
    }
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <Spinner />
      </div>
    );
  }

  if (!contactData) {
    return (
      <div className="flex justify-center items-center h-[90vh]">
        <h2 className="text-gray-300 text-lg">No Contact Details Found</h2>
      </div>
    );
  }

  const visibleSectionHandler = (contact) => {
    setIsCurrentUserView({
      isActive: true,
      data: contact,
    });
  };

  // we need to change that
  const sendResponseHandler = async (userId) => {}

  return (
    <div className="w-full h-[80vh] bg-gray-900 rounded-lg">
      <div className="w-full flex flex-col h-full">
        <div className="w-full flex items-center justify-start px-4 py-4 bg-gray-800 shadow-md">
          <div className="w-[350px] text-lg font-bold text-white">Contact Details</div>
          {currUserView.data && (
            <div className='w-full flex justify-between items-center'>
              <div className=' text-lg font-semibold uppercase'>{currUserView.data.name}</div>
              <div className=' text-base font-normal uppercase text-yellow-500'>{currUserView.data?.dateTime}</div>
            </div>
            )}
        </div>

        <div className="w-full flex justify-between h-full">
          {/* Sidebar */}
          <div className="w-[350px] overflow-y-scroll bg-gray-800 border-r border-gray-700 custom-scrollbar">
            {contactData.map((contact, index) => (
              <div
                key={index}
                onClick={() => visibleSectionHandler(contact)}
                className="w-full flex items-start justify-start gap-2 p-3 rounded-md transition duration-300 ease-in hover:bg-gray-700 cursor-pointer"
              >
                <div className="w-[50px] h-[50px] border-2 border-gray-500 flex justify-center items-center rounded-full">
                  <img
                    src={`https://api.dicebear.com/5.x/initials/svg?seed=${contact.name}`}
                    alt="user-img"
                    className="w-full h-full rounded-full object-cover"
                  />
                </div>
                <div className="flex flex-col">
                  <div className="text-base font-semibold text-gray-200">{contact.name}</div>
                  <div className="text-sm font-light text-gray-400">{contact.subject}</div>
                </div>
              </div>
            ))}
          </div>

          {/* Contact details view */}
          <div className="w-full flex flex-col justify-between p-4 bg-gray-900">
            <div className="w-full overflow-y-scroll h-full flex flex-col justify-start gap-4">
              {currUserView.isActive ? (
                <div className="w-full flex flex-col items-start gap-4 p-4 bg-gray-800 rounded-lg">
                  <div className="py-1 px-4 text-lg bg-gray-700 rounded-md text-gray-300">
                    {`Branch Name: ${currUserView.data.branch_name}`}
                  </div>
                  <div className="py-1 px-4 text-lg bg-gray-700 rounded-md text-gray-300">
                    {`Phone: ${currUserView.data.mobile_no}`}
                  </div>
                  <Link
                    to={`mailto:${currUserView.data.email}`}
                    className="py-1 px-4 text-lg bg-gray-700 rounded-md text-blue-400 hover:underline"
                  >
                    {currUserView.data.email}
                  </Link>
                  <div className="py-1 px-4 text-lg bg-gray-700 rounded-md text-gray-300">
                    {`Shedule Date: ${currUserView.data.shedule_date}`}
                  </div>
                  <div className="py-1 px-4 text-lg bg-gray-700 rounded-md text-gray-300">
                    {`Shedule Time: ${currUserView.data.shedule_for}`}
                  </div>
                  {
                    currUserView.data?.response !== "" && (
                      <div>
                        <div>Response</div>
                        <div className="py-1 px-4 text-lg bg-gray-700 rounded-md text-gray-300">
                          {`Shedule Time: ${currUserView.data.response}`}
                        </div>
                      </div>
                    )
                  }
                </div>
              ) : (
                <div className="w-full flex justify-center items-center text-gray-400">
                  No Messages Selected
                </div>
              )}
            </div>

            {/* Message input */}
            <div className="w-full h-[70px] flex items-center mt-4">
              <input
                type="text"
                name='message'
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                placeholder="Enter response message"
                className="w-full h-full px-4 bg-gray-800 text-lg text-white outline-none rounded-l-md"
              />
              <button onClick={() => sendResponseHandler(currUserView.data._id)} className="h-full px-6 bg-blue-600 hover:bg-blue-700 transition-all duration-300 rounded-r-md">
                <CiPaperplane size={24} className="text-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
