import React, { useContext, useEffect, useState } from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import shapeImg from '../../assets/shape.png'
import BranchCard from './BranchCard';
import {AppContext} from '../../context/AppContext'

const Branch = () => {
    const {branchData} = useContext(AppContext)
    const [screenCard, setScreenWithCard] = useState(2);

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: screenCard,
        slidesToScroll: 1,
    };

    useEffect(() => {
        // Function to handle screen resizing
        const handleResize = () => {
            if(window.innerWidth > 872){
                setScreenWithCard(2);
            }
            if(window.innerWidth < 700){
                setScreenWithCard(1);
            }
        };

        // Add event listener to handle window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

  return (
    <section className="branches-section p-100">
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="branche-head">
                        <img src={shapeImg} alt="" className='m-auto'/>
                        <h2>Explore Lion’s Den All Locations</h2>
                    </div>
                </div>
            </div>
            <div className="ser-slider">
                <Slider {...settings}>
                    {
                        branchData && branchData.map((branch) => (
                            <BranchCard
                                data={branch}
                            />
                        ))
                    }
                </Slider>
            </div>
        </div>
    </section>
  )
}

export default Branch