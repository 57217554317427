import { Link } from "react-router-dom";
import shapeImg from "../../assets/shape.png";

function BranchCard({data}) {
  return (
    <>
      <div className="branche-box">
        <div className="row">
          <div className="col-md-6">
            <div className="map-location">
              <iframe
                src={data.location_url}
                width="100%"
                height="100%"
                classNameName="border-none"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <div className="branches-text">
              <img src={shapeImg} alt="" />
              <h4>{`Lions Den Fight Club ${data.name}`}</h4>
              <address>
                {data.location}
              </address>
              <Link to={`/branch/${data._id}`} className="sec-btn">
                <span>More Information</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BranchCard;
